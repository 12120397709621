// Per jaar anders of DEFAULT
export const BAM_VALUES = {
  2023: { 1: 36000, 2: 43000, 3: 51500, 4: 64500, 5: 59500, 6: 67500, 7: 89500, 8: 68500, 9: 67500, 10: 64000, 11: 45500, 12: 47500 },
  2024: { 1: 60000, 2: 65000, 3: 65000, 4: 75000, 5: 80000, 6: 85000, 7: 95000, 8: 95000, 9: 95000, 10: 85000, 11: 80000, 12: 75000 },
  DEFAULT: { 1: 73800, 2: 79950, 3: 79950, 4: 92250, 5: 98400, 6: 104550, 7: 116850, 8: 116850, 9: 116850, 10: 104550, 11: 98400, 12: 92250 }, // 23% per maand bij vorige
  // Default = 2025 or more
}
BAM_VALUES['2018'] = BAM_VALUES['2023']
BAM_VALUES['2019'] = BAM_VALUES['2023']
BAM_VALUES['2020'] = BAM_VALUES['2023']
BAM_VALUES['2021'] = BAM_VALUES['2023']
BAM_VALUES['2022'] = BAM_VALUES['2023']

// Per jaar anders of DEFAULT
export const TARGET_VALUES = {
  2023: { 1: 40000, 2: 45000, 3: 70000, 4: 80000, 5: 80000, 6: 90000, 7: 100000, 8: 100000, 9: 100000, 10: 90000, 11: 60000, 12: 65000 },
  2024: { 1: 78000, 2: 85000, 3: 85000, 4: 98000, 5: 104000, 6: 111000, 7: 124000, 8: 124000, 9: 124000, 10: 111000, 11: 104000, 12: 98000 },
  DEFAULT: { 1: 97500, 2: 106250, 3: 106250, 4: 122500, 5: 130000, 6: 138750, 7: 155000, 8: 155000, 9: 155000, 10: 138750, 11: 130000, 12: 122500 }, // 25% per maand bij vorige
  // Default = 2025 or more
}
TARGET_VALUES['2018'] = TARGET_VALUES['2023']
TARGET_VALUES['2019'] = TARGET_VALUES['2023']
TARGET_VALUES['2020'] = TARGET_VALUES['2023']
TARGET_VALUES['2021'] = TARGET_VALUES['2023']
TARGET_VALUES['2022'] = TARGET_VALUES['2023']
