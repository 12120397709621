<template>
  <div :key="renderKey" class="flex flex-col gap-8">
    <StatsCalculatedAt v-if="!homepage" :calculated-at="calculatedAt" />

    <UILoading v-if="loading || loadingVergelijk" />
    <div v-else class="grid gap-4" :class="{ 'xl:grid-cols-2': homepage, 'xl:grid-cols-1': !homepage }">
      <div class="card">
        <CaretRow v-model:open="isBoekingswaardeOpen">
          <h2>{{ titlePrefix }} - Boekingswaarde</h2>
        </CaretRow>
        <StatsCalculatedAt v-if="homepage && isBoekingswaardeOpen" :calculated-at="calculatedAt" />
        <div v-if="isBoekingswaardeOpen" class="grid gap-4" :class="{ 'xl:grid-cols-2': homepage, 'xl:grid-cols-5': !homepage }">
          <BamTarget
            v-for="timeframe in timeframes"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="getWaarde(statistieken[timeframe])"
            :change="props.filtersVergelijken ? usePercentageIncrease(getWaarde(statistieken[timeframe]), getWaarde(statistiekenVergelijk[timeframe])) : undefined"
            :bam="bams.omzet[timeframe]"
            :target="targets.omzet[timeframe]"
            currency
          >
            <template #suffix>
              <ul class="mt-2 pt-2 border-t border-dashboard-dark">
                <li class="flex flex-row justify-between">
                  <div>
                    <span>
                      &euro;&nbsp;{{ formatPrice(statistieken[timeframe].boekingswaarde) }} Bevestigde waarde
                    </span>
                    <small class="block pl-4">
                      &euro;&nbsp;{{ formatPrice(statistieken[timeframe].gegeven_korting) }} Korting
                    </small>
                    <small class="block pl-4">
                      &euro;&nbsp;{{ formatPrice(statistieken[timeframe].gegeven_korting_promotie) }} Waardebon/Promotie
                    </small>
                    <small class="block pl-4">
                      &euro;&nbsp;{{ formatPrice(statistieken[timeframe].premium_kosten) }} Premium waarde
                    </small>
                  </div>
                </li>
                <li class="flex flex-row justify-between">
                  <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].dagwaarde_annulatiekosten) }}
                    Annulatiekosten</span>
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>

      <div class="card">
        <CaretRow v-model:open="isBoekingenAantalOpen">
          <h2>{{ titlePrefix }} - Aantal</h2>
        </CaretRow>
        <StatsCalculatedAt v-if="homepage && isBoekingenAantalOpen" :calculated-at="calculatedAt" />
        <div v-if="isBoekingenAantalOpen" class="grid gap-4" :class="{ 'xl:grid-cols-2': homepage, 'xl:grid-cols-5': !homepage }">
          <BamTarget
            v-for="timeframe in timeframes"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="statistieken[timeframe].aantal_ritten"
            :bam="bams.reservaties[timeframe]"
            :target="targets.reservaties[timeframe]"
          >
            <template #prefix>
              <ul>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].aantal_ritten }} Boekingen</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].aantal_ritten" :previous-value="statistiekenVergelijk[timeframe].aantal_ritten" />
                </li>
                <li class="py-2">
                  <hr class="border-t border-dashboard-dark">
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ websiteBoekingenCount(statistieken[timeframe]) }} Website</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="websiteBoekingenCount(statistieken[timeframe])" :previous-value="websiteBoekingenCount(statistiekenVergelijk[timeframe])" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_telefonisch }} Telefonisch</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_telefonisch" :previous-value="statistiekenVergelijk[timeframe].via_telefonisch" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_mail }} Mail</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_mail" :previous-value="statistiekenVergelijk[timeframe].via_mail" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_chat }} Online chat</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_chat" :previous-value="statistiekenVergelijk[timeframe].via_chat" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_whatsapp }} Whatsapp</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_whatsapp" :previous-value="statistiekenVergelijk[timeframe].via_whatsapp" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_facebook }} Facebook/Messenger</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_facebook" :previous-value="statistiekenVergelijk[timeframe].via_facebook" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_instagram }} Instagram</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_instagram" :previous-value="statistiekenVergelijk[timeframe].via_instagram" />
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].via_onderaanneming }} In onderaanneming</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].via_onderaanneming" :previous-value="statistiekenVergelijk[timeframe].via_onderaanneming" />
                </li>
                <li class="py-2">
                  <hr class="border-t border-dashboard-dark">
                </li>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].premium }} Waarvan premium</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].premium" :previous-value="statistiekenVergelijk[timeframe].premium" />
                </li>
              </ul>
            </template>
            <template #suffix>
              <ul class="mt-2 pt-2 border-t border-dashboard-dark">
                <li>
                  <span>
                    Totale boekingswaarde
                  </span>
                  <div class="flex flex-row justify-between text-xs">
                    <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].boekingswaarde) }}</span>
                    <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].boekingswaarde" :previous-value="statistiekenVergelijk[timeframe].boekingswaarde" />
                  </div>
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>

      <div v-if="!props.homepage" class="card">
        <CaretRow v-model:open="isBoekingenKinderenOpen">
          <h2>{{ titlePrefix }} - Kinderen</h2>
        </CaretRow>
        <StatsCalculatedAt v-if="homepage && isBoekingenKinderenOpen" :calculated-at="calculatedAt" />
        <div v-if="isBoekingenKinderenOpen" class="grid xl:grid-cols-5 gap-4">
          <BamTarget
            v-for="timeframe in timeframes"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="statistieken[timeframe].met_kinderen"
          >
            <template #prefix>
              <ul>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].met_kinderen }} Boekingen met kinderen</span>
                </li>
              </ul>
            </template>
            <template #suffix>
              <ul class="pt-2 border-t border-dashboard-dark">
                <li class="flex flex-col justify-between">
                  <span>{{ statistieken[timeframe].met_kinderstoelen }} Boekingen met kinderstoelen</span>
                  <span>{{ statistieken[timeframe].met_kinderzitjes }} Boekingen met kinderzitjes</span>
                  <span>{{ statistieken[timeframe].met_maxicosis }} Boekingen met maxicosis</span>
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, defineEmits, ref, watch } from 'vue'

import { useMarketingBamTargets, useMarketingValues } from '@/pages/stats/Dashboard2/useMarketingStats'

import BamTarget from '@/components/Charts/BamTarget.vue'
import StatsCalculatedAt from '@/components/Stats/CalculatedAt.vue'
import UILoading from '@/components/UI/Loading.vue'

import { formatPrice } from '@/functions/formatNumber'
import { capitalize } from '@/functions/formatText'
import usePercentageIncrease from '@/hooks/usePercentageIncrease'
import useRenderKey from '@/hooks/useRenderKey'

const props = defineProps({
  filters: Object,
  filtersVergelijken: Object,
  renderKey: [String, Number],
  homepage: {
    type: Boolean,
    default: false,
  },
  titlePrefix: {
    type: String,
    default: 'Boekingen',
  },
  isDefaultOpen: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['open'])
const PercentageChange = defineAsyncComponent(() => import('@/components/UI/Labels/PercentageChange.vue'))
const CaretRow = defineAsyncComponent(() => import('@/components/UI/CaretRow.vue'))

const isBoekingswaardeOpen = ref(!!props.isDefaultOpen || !props.homepage)
const isBoekingenAantalOpen = ref(!!props.isDefaultOpen || !props.homepage)
const isBoekingenKinderenOpen = ref(!!props.isDefaultOpen || !props.homepage)

watch(isBoekingswaardeOpen, val => {
  if (props.homepage && isBoekingenAantalOpen.value !== val) isBoekingenAantalOpen.value = val
  emit('open', val)
})
watch(isBoekingenAantalOpen, val => {
  if (props.homepage && isBoekingswaardeOpen.value !== val) isBoekingswaardeOpen.value = val
  emit('open', val)
})
watch(isBoekingenKinderenOpen, val => {
  emit('open', val)
})

useRenderKey(props.renderKey)

const apiData = computed(() => ({ ...(props.filters || {}), homepage: !!props.homepage }))
const apiDataVergelijk = computed(() => props.filtersVergelijken ? { ...(props.filtersVergelijken || {}), homepage: !!props.homepage } : null)

const {
  bams,
  targets,
} = useMarketingBamTargets({
  year: apiData.value?.year,
  month: apiData.value?.month,
  avg_price: props.filters?.avg_price,
})

const {
  loading,
  calculatedAt,
  statistieken,
} = useMarketingValues({
  apiData,
  endpoint: 'STATS_OMZET_BOEKINGEN',
})

const {
  loading: loadingVergelijk,
  statistieken: statistiekenVergelijk,
} = useMarketingValues({
  apiData: apiDataVergelijk,
  endpoint: 'STATS_OMZET_BOEKINGEN',
})

const timeframes = computed(() => {
  const result = Object.keys(statistieken.value)
  if (props.homepage) return result.slice(0, 2)
  return result
})

const websiteBoekingenCount = item => {
  return (item.aantal_ritten || 0) - (item.via_mail || 0) - (item.via_telefonisch || 0) - (item.via_chat || 0)
}

const getWaarde = obj => Math.max(obj?.boekingswaarde, 0) + Math.max(obj?.boekingswaarde_annulatiekosten, 0)
</script>
