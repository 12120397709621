import { computed, onMounted, ref, unref, watch } from 'vue'

import { BAM_VALUES, TARGET_VALUES } from '@/constants/BAM_TARGET_VALUES'
import { daysInMonth } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useBamTargetValues from '@/hooks/useBamTargetValues'

const DEFAULT_STATISTIEKEN = () => ({ daily: {}, weekly: {}, monthly: {}, quarterly: {}, yearly: {} })
const DEFAULT_GRAFIEK_DATA = () => ({})

export const useMarketingBamTargets = ({ year, month, avg_price }) => {
  const daysInThisMonth = computed(() => {
    const now = new Date()
    return daysInMonth(year || now.getFullYear(), month || (now.getMonth() + 1))
  })

  const bams = computed(() => {
    const BAM_VALUES_CURRENT = BAM_VALUES[year] || BAM_VALUES.DEFAULT
    return useBamTargetValues(avg_price, daysInThisMonth.value, BAM_VALUES_CURRENT, month)
  })

  const targets = computed(() => {
    const TARGET_VALUES_CURRENT = TARGET_VALUES[year] || TARGET_VALUES.DEFAULT
    return useBamTargetValues(avg_price, daysInThisMonth.value, TARGET_VALUES_CURRENT, month)
  })

  return { bams, targets }
}

export const useMarketingGraph = ({ apiData, endpoint }) => {
  const api = useApi()
  const apiDataRef = computed(() => unref(apiData || {}))

  const loading = ref(true)
  const calculatedAt = ref(null)
  const grafiek = ref(DEFAULT_GRAFIEK_DATA())

  const getData = async _options => {
    const options = _options || { force: false }
    if (!options?.force && loading.value) {
      loading.value = false
      return
    }

    loading.value = true
    const params = Object.assign({}, unref(apiDataRef) || {})
    const paramsGrafiek = Object.assign({}, params, unref(apiData) || {})

    if (!Object.keys(params).length) {
      grafiek.value = DEFAULT_GRAFIEK_DATA()
      loading.value = false
      return
    }

    const { data } = await api(endpoint, paramsGrafiek).catch(() => ({}))
    if (data.grafiek) grafiek.value = data.grafiek
    if (data.calculatedAt) calculatedAt.value = data.calculatedAt

    loading.value = false
  }

  onMounted(() => {
    getData({ force: true }).then(() => {
      watch(apiData, () => getData())
    })
  })

  return {
    grafiek,
    calculatedAt,
    loading,
    getData,
  }
}

export const useMarketingValues = ({ apiData, endpoint }) => {
  const api = useApi()
  const apiDataRef = computed(() => unref(apiData || {}))

  const loading = ref(true)
  const calculatedAt = ref(null)
  const statistieken = ref(DEFAULT_STATISTIEKEN())

  const getData = async _options => {
    const options = _options || { force: false }
    if (!options?.force && loading.value) {
      loading.value = false
      return
    }

    loading.value = true
    const params = Object.assign({}, unref(apiDataRef) || {})

    if (!Object.keys(params).length) {
      statistieken.value = DEFAULT_STATISTIEKEN()
      loading.value = false
      return
    }

    const { data } = await api(endpoint, params).catch(() => ({}))
    if (data && data.statistieken) {
      statistieken.value = data.statistieken
      calculatedAt.value = data.calculatedAt || calculatedAt.value
    }

    loading.value = false
  }

  onMounted(() => {
    getData({ force: true }).then(() => {
      watch(apiData, () => getData())
    })
  })

  return {
    statistieken,
    calculatedAt,
    loading,
    getData,
  }
}
